$(document).ready(function(){

    // navi burger
    $('#nav-icon').click(function() {
        $("#nav-icon").toggleClass("open");
        $("nav").toggleClass("fixed");
        $("nav").toggleClass("mobilehide");
        //$("header").toggleClass("fixed");
    });

    // subnav mobile
    $('nav .icon-angle-down.mobile').click(function() {
        // $(this).toggleClass("icon-angle-down");
        // $(this).toggleClass("icon-angle-up");
        $(this).toggleClass("open");
        var dataid = $(this).data("id");
        $(".subnav-id-" + dataid).toggle( "slow", function() {
            // Animation complete.
        });
    });

    $('nav .icon-angle-down.open').click(function() {
        $(this).toggleClass("open");
        var dataid = $(this).data("id");
        $(".subnav-id-" + dataid).toggle( "slow", function() {
            // Animation complete.
        });
    });

    $('.dropdown:last').addClass("last-dropdown");



    /*
    $('#nav-icon').click(function() {
        $("#nav-icon").toggleClass("open");
        $(".navigation").toggleClass("active");
        $("header").toggleClass("active");
    });

    // subnav mobile
    $('.navigation .icon-angle-down').click(function() {
        // $(this).toggleClass("icon-angle-down");
        // $(this).toggleClass("icon-angle-up");
        $(this).toggleClass("open");
        var dataid = $(this).data("id");
        $(".subnav-id-" + dataid).toggle( "slow", function() {
            // Animation complete.
        });
    });

    $('.navigation .icon-angle-down.open').click(function() {
        $(this).toggleClass("open");
        var dataid = $(this).data("id");
        $(".subnav-id-" + dataid).toggle( "slow", function() {
            // Animation complete.
        });
    });
    */

$('.maplink').click(function() {
    var dataid = $(this).data("id");
    $(".grid-standorte .gmaps").hide();
    $(".map-" + dataid).show();
});



if ($(".icon-home")[0]){

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();

        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();

        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $(window).on('resize scroll', function() {
        if ($('.icon-home').isInViewport()) {
            $(".icon-home").removeClass("icon-home-inactive");

        }
    });

}


});

